import { useHistory } from 'react-router-dom';

import { useKeycloak } from './useKeycloak';

export function useLogin() {
  const { signIn, errorMessage } = useKeycloak();
  const history = useHistory();

  const logIn = async (data) => {
    await signIn(data);
    history.replace('/');
  };

  return { logIn, errorMessage };
}
