/* eslint-disable security/detect-object-injection */
import { makeStyles } from '@bayon/commons';

const getTypeConfig = (theme, type = 'info') => {
  return {
    success: {
      background: theme.colors.successBackground,
      color: theme.colors.successDark,
    },
    error: {
      background: theme.colors.dangerBackground,
      color: theme.colors.dangerDark,
    },
    info: {
      background: theme.colors.infoBackground,
      color: theme.colors.infoDark,
    },
    warning: {
      background: theme.colors.warningBackground,
      color: theme.colors.warningDark,
    },
  }[type];
};

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    backgroundColor: ({ type }) => getTypeConfig(theme, type).background,
  },
  icon: {
    fill: ({ type }) => getTypeConfig(theme, type).color,
    minHeight: 16,
    minWidth: 16,
    marginRight: 8,
  },
  message: {
    marginLeft: 8,
    color: ({ type }) => getTypeConfig(theme, type).color,
  },
  toastButtonAction: {
    fontWeight: 600,
    textDecoration: 'underline',
    lineHeight: '21px',
    cursor: 'pointer',
    color: ({ type }) => getTypeConfig(theme, type).color,
  },
}));
