import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute, Loader } from '../components';
import { Login, Merge } from '../pages';
import { useKeycloak } from '../hooks';

export const Routes = () => {
  const auth = useKeycloak();

  if (auth.isLoading) {
    return <Loader />;
  }

  return (
    <Switch key={Math.random()}>
      <Route path="/login">
        <Login />
      </Route>
      <PrivateRoute path="/">
        <Merge />
      </PrivateRoute>
    </Switch>
  );
};
