import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useKeycloak } from '../../hooks';

export const PrivateRoute = ({ children, ...rest }) => {
  const auth = useKeycloak();

  return (
    <Route
      {...rest}
      render={() =>
        auth?.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
