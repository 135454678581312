import React from 'react';

import { Loader as BayonLoader } from '@bayon/commons';

import { useStyles } from './Loader.styles';

export function Loader(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BayonLoader {...props} />
    </div>
  );
}
