import React, { useMemo } from 'react';

import { Button, Typography } from '@bayon/commons';
import { useTranslation } from '@bayon/i18n';

import { useFields } from '../../hooks';
import { getResultCounter } from '../../utils';

import { Container, Search } from './ResultFeedback.styles';

export const ResultFeedback = () => {
  const { t } = useTranslation();
  const { fieldGroups, searchDebounced, onClearFilter } = useFields();

  const count = useMemo(() => getResultCounter(fieldGroups), [fieldGroups]);

  if (!searchDebounced) {
    return null;
  }

  return (
    <Container variant="flex">
      <Typography>{t('RESULTS_FOR', { count })}</Typography>
      <Search weight="semibold">{searchDebounced}.</Search>
      <Button size="small" variant="subtle-primary" onClick={onClearFilter}>
        {t('CLEAR_SEARCH')}
      </Button>
    </Container>
  );
};
