/* global */

import React, { useCallback } from 'react';

import { Grid, EmptyState } from '@bayon/commons';
import { useTranslation } from '@bayon/i18n';

import { FieldSelect, Loader } from '../../../../components';
import { useWordDocument, useFields } from '../../../../hooks';

export function FillFields() {
  const { t } = useTranslation(['common', 'editorWordAddin']);

  const { highlightTag, errors } = useWordDocument();
  const {
    deleteField,
    updateFieldValue,
    fieldArray,
    fieldsConfigStatus,
    loadingFieldGroups,
  } = useFields();

  const handleFocus = useCallback(async (item) => {
    await highlightTag(item.id, true);
  }, []);

  const handleBlur = useCallback(async (item) => {
    await highlightTag(item.id, false);
  }, []);

  if (fieldsConfigStatus || loadingFieldGroups) {
    const label = t(fieldsConfigStatus ?? 'CONFIG_MERGING_FIELDS');
    return <Loader aria-label={label} label={label} />;
  }

  if (!fieldArray?.length) {
    return (
      <EmptyState
        size="small"
        icon="FileClose"
        title={t('NO_FIELDS_TO_SHOW')}
        subtitle={t('ADD_FIELDS_TO_SELECT')}
      />
    );
  }

  return (
    <Grid xs={12}>
      {fieldArray?.map((item) => (
        <FieldSelect
          key={`fill_field_${item.id}`}
          field={item}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={updateFieldValue}
          onDelete={deleteField}
          required={!errors?.includes(item.nome)}
        />
      ))}
    </Grid>
  );
}
