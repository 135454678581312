import React, { useState } from 'react';

import { useFields } from '../../hooks';

import { TreeItem } from './fragments';

export function TreeView() {
  const { fieldGroups } = useFields();
  const [expanded, setExpanded] = useState('');

  const handleExpandedToggle = (value) => {
    setExpanded((prev) => (prev === value ? '' : value));
  };

  return fieldGroups.map((item) => (
    <TreeItem
      key={item.id}
      {...item}
      {...{ expanded, onExpand: handleExpandedToggle }}
    />
  ));
}
