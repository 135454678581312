/* eslint-disable no-unsafe-optional-chaining */
/* global Office */

import { useState, useEffect } from 'react';

import bayonConfig from '@core/bayon.config';

import { useWordProperties, useKeycloak } from '.';

export function useCustomProperties() {
  const { user } = useKeycloak();
  const { wordProperties } = useWordProperties();
  const [customProperties, setCustomProperties] = useState();
  const { contentDocumentsAction } = bayonConfig?.externalUrls;

  useEffect(() => {
    if (user && !customProperties) {
      getProperties();
    }
  }, [user]);

  const getProperties = async () => {
    let properties = {};
    if (Office.context.platform === Office.PlatformType.OfficeOnline) {
      const fileName = await wordProperties.getFileName();
      const token = localStorage.getItem('token');
      const tenant = localStorage.getItem('tenant');
      const response = await fetch(
        `${contentDocumentsAction}/documento/obter-metadados?blobId=${fileName}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            tenant,
          },
        }
      );
      properties = await response.json();
    } else {
      properties = await wordProperties.get();
    }
    setCustomProperties(properties);
  };

  return { customProperties, baseUrl: contentDocumentsAction };
}
