import React from 'react';

import PropTypes from 'prop-types';
import { apm } from '@elastic/apm-rum';

import { ErrorScreen } from './fragments';

export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (apm?.isActive()) {
      apm?.addLabels({ front: 'ErrorBoundary' });
      apm?.captureError(error);
      apm?.captureError(errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};
