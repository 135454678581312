import React from 'react';

import PropTypes from 'prop-types';

import { Collapse, CollapseTitle } from '@bayon/commons';

import { useFields } from '../../../../hooks';

import { TreeSubItem } from './fragments';

import { useStyles } from './TreeItem.styles';

export function TreeItem({
  id_tipo,
  descricao_tipo,
  campos,
  expanded,
  onExpand,
}) {
  const classes = useStyles();
  const { searchDebounced } = useFields();

  const isExpanded = !!searchDebounced || expanded === id_tipo;

  const handleExpand = () => {
    if (searchDebounced) {
      return;
    }
    onExpand(id_tipo);
  };

  if (!campos?.length) {
    return null;
  }

  return (
    <Collapse
      transparent
      size="small"
      data-testid={`tree-item-${id_tipo}`}
      expanded={isExpanded}
      onChange={handleExpand}
      className={classes.collapse}
    >
      <CollapseTitle>{descricao_tipo}</CollapseTitle>
      <div className={classes.content}>
        {isExpanded &&
          campos?.map((item) => (
            <TreeSubItem key={item.id} type={id_tipo} {...item} />
          ))}
      </div>
    </Collapse>
  );
}

TreeItem.propTypes = {
  id_tipo: PropTypes.string.isRequired,
  descricao_tipo: PropTypes.string.isRequired,
  campos: PropTypes.array,
  expanded: PropTypes.string.isRequired,
  onExpand: PropTypes.func.isRequired,
};
