/* global Office */

export const getDocumentByteArray = () => {
  return new Promise(function (resolve, reject) {
    function onGotAllSlices(docdataSlices) {
      const docdata = docdataSlices.reduce((acc, item) => acc.concat(item), []);
      resolve(new Uint8Array(docdata));
    }

    function getSliceAsync(
      file,
      nextSlice,
      sliceCount,
      docdataSlices,
      slicesReceived
    ) {
      file.getSliceAsync(nextSlice, function (sliceResult) {
        if (sliceResult.status === 'succeeded') {
          docdataSlices[sliceResult.value.index] = sliceResult.value.data;
          if (++slicesReceived === sliceCount) {
            file.closeAsync();
            onGotAllSlices(docdataSlices);
          } else {
            getSliceAsync(
              file,
              ++nextSlice,
              sliceCount,
              docdataSlices,
              slicesReceived
            );
          }
        } else {
          file.closeAsync();
          reject(sliceResult.error.message);
        }
      });
    }

    Office.context.document.getFileAsync(
      Office.FileType.Compressed,
      {},
      function (result) {
        if (result.status === 'succeeded') {
          let myFile = result.value;
          let sliceCount = myFile.sliceCount;
          let slicesReceived = 0;
          let docdataSlices = [];
          getSliceAsync(myFile, 0, sliceCount, docdataSlices, slicesReceived);
        } else {
          reject(result.error.message);
        }
      }
    );
  });
};
