import React, { useMemo } from 'react';

import { PropTypes } from 'prop-types';

import TimesCircle from '@bayon/svg-icons/icons/TimesCircle';
import { Typography } from '@bayon/commons';
import { useTranslation, Trans } from '@bayon/i18n';

import { useStyles } from './ContentMessage.styles';

export const ContentMessage = ({ errors }) => {
  const { t } = useTranslation(['common', 'editorWordAddin']);
  const classes = useStyles({ type: 'error' });

  const message = useMemo(() => {
    const abridge = errors.length > 3;

    let errorMessage = errors.slice(0, abridge ? 3 : errors.length).join(', ');

    if ([2, 3].includes(errors.length)) {
      errorMessage = errorMessage.replace(/,([^,]*)$/, ' ' + t('AND') + '$1');
    }

    if (abridge) {
      errorMessage += ` ${t('AND_OTHERS', { count: errors.length - 3 })}`;
    }
    return errorMessage;
  }, [errors]);

  return (
    <div role="alert" className={classes.container}>
      <TimesCircle data-testid="icon-error" className={classes.icon} />
      <Typography size={14} className={classes.message}>
        <Trans i18nKey="CANT_REPLACE" count={errors?.length}>
          <b style={{ fontWeight: 600 }}>{{ field: message }}</b>
        </Trans>
      </Typography>
    </div>
  );
};

ContentMessage.propTypes = {
  errors: PropTypes.object,
};
