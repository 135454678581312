import React from 'react';

import SearchIcon from '@bayon/svg-icons/icons/Search';
import CloseIcon from '@bayon/svg-icons/icons/Close';
import { useTranslation } from '@bayon/i18n';

import { useFields } from '../../hooks';

import { useStyles } from './InputSearch.styles';

export function InputSearch() {
  const classes = useStyles();
  const { t } = useTranslation('editorWordAddin');
  const { setSearch, search, onClearFilter } = useFields();

  return (
    <div className={classes.container}>
      <div className={classes.containerInputWithIcon}>
        <input
          value={search}
          className={classes.input}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('SEARCH_TEXT_FIELDS')}
        />

        {!search ? (
          <SearchIcon data-testid="search-icon" className={classes.icon} />
        ) : (
          <CloseIcon
            data-testid="clear-icon"
            className={classes.icon}
            onClick={onClearFilter}
          />
        )}
      </div>
    </div>
  );
}
