/* eslint-disable security/detect-object-injection */
/* eslint-disable require-await */
/* global Office */

import { getJSONField, findJSONString } from './JSONUtils';

const loadFields = async (i, { fields }) => fields[i].load(['code', 'result']);

const createContentControls = async (
  i,
  { context, fields, mappedFields, parsedCodes, validFields, contentControls }
) => {
  if (!findJSONString(fields[i].code)) {
    throw new Error();
  }

  const validField = mappedFields.get(getJSONField(fields[i].code, 'nome'));

  if (!validField) {
    throw new Error();
  }

  parsedCodes.push(fields[i].code);
  validFields.push(validField);

  let contentControl;
  if (Office.context.platform === Office.PlatformType.PC) {
    fields[i].select();
    let selection = context.document.getSelection();
    contentControl = selection.insertContentControl();
  } else {
    contentControl = fields[i].result.insertContentControl();
  }

  contentControls.push(contentControl);

  try {
    await context.sync();
  } catch (err) {
    throw new Error();
  }
};

const loadContentControls = async (i, { context, fields, contentControls }) => {
  context.load(fields[i].result, 'text');
  contentControls[i].load('tag,text,id,title,appearance');
};

const createFields = async (
  i,
  {
    fields,
    validFields,
    parsedCodes,
    getFieldOptions,
    contentControls,
    createdFields,
    configTagProperties,
  }
) => {
  const isFieldMerged = !fields[i].result.text.match(/\[.+\]/g);

  const { id, nome, type, descricao } = validFields[i];

  const fetchedField = isFieldMerged
    ? { valores: [getJSONField(parsedCodes[i], 'valor')] }
    : await getFieldOptions(id);

  configTagProperties({
    contentControl: contentControls[i],
    field: {
      id,
      nome,
      type,
      values: fetchedField?.valores,
    },
    parsedCode: findJSONString(parsedCodes[i]),
  });

  createdFields.push({
    id: contentControls[i].id,
    fieldId: id,
    nome,
    type,
    descricao,
    values: fetchedField?.valores,
  });
};

export const CONVERT_PIPELINE = Object.freeze([
  { function: loadFields, autoSync: true },
  { function: createContentControls, autoSync: false },
  { function: loadContentControls, autoSync: true },
  { function: createFields, autoSync: true },
]);
