/* eslint-disable no-unsafe-optional-chaining */
import React, { useMemo, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import bayonConfig from '@core/bayon.config';

import { useTranslation } from '@bayon/i18n';

import { decodeToken } from '../utils';

export const KeycloakContext = React.createContext();

const enumLoginError = {
  FIRST_LOGIN: 'FIRST_LOGIN',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  USER_PASSWORD_INVALID: 'USER_PASSWORD_INVALID',
  LOTACAO_FAILED: 'LOTACAO_FAILED',
};

const getExpireInTime = (tokenParsed, timeSkew) => {
  return (tokenParsed?.exp - new Date().getTime() / 1000 + timeSkew) * 1000;
};

const getTimeSkew = (tokenParsed, timeLocal) => {
  return Math.floor(timeLocal / 1000) - tokenParsed?.iat;
};

const getHeaders = () => {
  const token = localStorage.getItem('token');
  const tenant = localStorage.getItem('tenant');
  return {
    'Content-Type': 'application/json',
    authorization: `Bearer ${token}`,
    tenant: tenant,
  };
};

const persistRefreshToken = (refresh_token) => {
  if (refresh_token) {
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem(
      'refresh_token_parsed',
      JSON.stringify(decodeToken(refresh_token))
    );
    return;
  }
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('refresh_token_parsed');
};

const persistIdToken = (id_token) => {
  if (id_token) {
    localStorage.setItem('id_token', id_token);
    localStorage.setItem(
      'id_token_parsed',
      JSON.stringify(decodeToken(id_token))
    );
    return;
  }
  localStorage.removeItem('id_token');
  localStorage.removeItem('id_token_parsed');
};

export const KeycloakProvider = ({ children }) => {
  const { t } = useTranslation('editorWordAddin');
  const [expiresIn, setExpiresIn] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { clientId, realm, url } = bayonConfig?.keycloak;
  const { url: apolloURI } = bayonConfig?.apollo;
  const [oidcConfig, setOidcConfig] = useState();
  const oidcEndointsURI = `${url}/realms/${realm}/.well-known/openid-configuration`;

  const environment = useMemo(() => {
    return { clientId, realm, url, apolloURI };
  }, []);

  useEffect(() => {
    setupOidcEndoints();
  }, []);

  useEffect(() => {
    if (oidcConfig) {
      validateToken();
    }
  }, [oidcConfig]);

  useEffect(() => {
    if (expiresIn) {
      const timer = setTimeout(() => {
        onTokenExpired();
      }, expiresIn);
      return () => clearTimeout(timer);
    }
  }, [expiresIn]);

  const onTokenExpired = () => {
    signOut();
    setErrorMessage(t(enumLoginError.TOKEN_EXPIRED));
  };

  const validateToken = async () => {
    setIsLoading(true);

    const token = localStorage.getItem('token');
    let timeLocal = new Date().getTime();
    try {
      await fetch(oidcConfig['userinfo_endpoint'], {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      });

      timeLocal = (timeLocal + new Date().getTime()) / 2;

      const tokenParsed = JSON.parse(localStorage.getItem('token_parsed'));
      const refreshToken = localStorage.getItem('refresh_token');
      let timeSkew;

      if (timeLocal) {
        timeSkew = getTimeSkew(tokenParsed, timeLocal);
      }

      isTokenExpiredValidation({
        tokenParsed,
        refreshToken,
        timeSkew,
      });
      await getUserData();
      setIsLoading(false);
    } catch (e) {
      signOut();
      setErrorMessage(
        t(token ? enumLoginError.TOKEN_EXPIRED : enumLoginError.FIRST_LOGIN)
      );
    }
  };

  const setupOidcEndoints = async () => {
    let response = await fetch(oidcEndointsURI, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    setOidcConfig(data);
  };

  const getUserData = async () => {
    try {
      let response = await fetch(environment.apolloURI, {
        method: 'POST',
        body: JSON.stringify(bayonConfig?.queries?.usuario),
        headers: getHeaders(),
      });

      const { data } = await response.json();
      localStorage.setItem(
        'lotacao',
        data.usuario.lotacao_principal.sequencial
      );
      localStorage.setItem('apolloURI', environment.apolloURI);
      setUser(data.usuario);
      setErrorMessage();
    } catch (e) {
      signOut();
      setErrorMessage(t(enumLoginError.LOTACAO_FAILED));
    }
  };

  const signIn = async ({ username, password, remember }) => {
    const myHeaders = new Headers();
    const urlencoded = new URLSearchParams();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    urlencoded.append('grant_type', 'password');
    urlencoded.append('username', username);
    urlencoded.append('password', password);
    urlencoded.append('client_id', environment.clientId);

    if (remember) {
      urlencoded.append('rememberMe', 'on');
    }

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    try {
      let timeLocal = new Date().getTime();
      const response = await fetch(
        oidcConfig['token_endpoint'],
        requestOptions
      );
      const data = await response.json();
      timeLocal = (timeLocal + new Date().getTime()) / 2;

      if (data?.error) {
        signOut();
        setErrorMessage(t(enumLoginError.USER_PASSWORD_INVALID));
        return;
      }

      setToken(data, timeLocal);
      await getUserData();
    } catch (e) {
      signOut();
      setErrorMessage(t(enumLoginError.USER_PASSWORD_INVALID));
    }
  };

  const clearStorage = () => {
    localStorage.removeItem('lotacao');
    localStorage.removeItem('apolloURI');
    localStorage.removeItem('token');
    localStorage.removeItem('token_parsed');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_parsed');
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_token_parsed');
  };

  const signOut = () => {
    clearStorage();
    setIsLoading(false);
    setUser();
    setErrorMessage();
  };

  const isTokenExpiredValidation = ({
    tokenParsed,
    refreshToken,
    timeSkew,
  }) => {
    if (!tokenParsed || !refreshToken) {
      throw new Error('Not authenticated');
    }

    if (timeSkew) {
      return true;
    }
    const minValidity = 5;
    let willExpireIn = getExpireInTime(tokenParsed, timeSkew);
    if (minValidity) {
      if (isNaN(minValidity)) {
        throw new Error('Invalid minValidity');
      }
      willExpireIn -= minValidity;
    }
    if (!willExpireIn) {
      onTokenExpired();
      return;
    }
    setExpiresIn(willExpireIn);
  };

  const setToken = ({ access_token, refresh_token, id_token }, timeLocal) => {
    const tokenParsed = decodeToken(access_token);

    localStorage.setItem('token', access_token);
    localStorage.setItem('token_parsed', JSON.stringify(tokenParsed));

    persistRefreshToken(refresh_token);
    persistIdToken(id_token);

    let timeSkew;
    if (timeLocal) {
      timeSkew = getTimeSkew(tokenParsed, timeLocal);
    }

    if (timeSkew) {
      const willExpireIn = getExpireInTime(tokenParsed, timeSkew);

      if (!willExpireIn) {
        onTokenExpired();
        return;
      }

      setExpiresIn(willExpireIn);
    }
  };

  return (
    <KeycloakContext.Provider
      value={{
        user,
        errorMessage,
        signIn,
        signOut,
        validateToken,
        isLoading,
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
