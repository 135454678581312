import React, { useState, useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';

import { useTranslation } from '@bayon/i18n';
import { useToast } from '@bayon/commons';

import { useWordDocument, useFieldsFetcher } from '../hooks';
import {
  transformFieldValues,
  parseFieldValue,
  extractFieldValue,
  extractFulfilledFields,
  persistNewMultiValuedFields,
  transformFieldsToMap,
  getPersistedFields,
  removePersistedField,
  updatePersistedFieldValue,
  persistField,
  getJSONField,
} from '../utils';
import { useStyles as useToastStyles } from '../components/StyledToast';

export const FieldsContext = React.createContext({});

export const FieldsProvider = ({ children }) => {
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 500);
  const [fields, setFields] = useState(new Map());
  const [fieldsConfigStatus, setFieldsConfigStatus] =
    useState('GETTING_FIELDS');
  const [hasPendings, setHasPendings] = useState(false);

  const toastClasses = useToastStyles();

  const { enqueueToast } = useToast();
  const { t } = useTranslation('editorWordAddin');
  const {
    addTag,
    removeTag,
    updateTag,
    getDocumentTags,
    customProperties,
    getUnmergeDocumentFields,
    updateFieldsStatus,
  } = useWordDocument();

  useEffect(() => {
    setHasPendings([...fields.values()].some((field) => !field.value));
  }, [fields]);

  const { fieldGroups, loadingFieldGroups, getFieldOptions } = useFieldsFetcher(
    {
      skip: !customProperties?.cdprocessoni,
      variables: {
        filter: { campo: { nome: searchDebounced } },
      },
      async onCompleted({ campos_agrupados_por_tipo }) {
        setFieldsConfigStatus('GETTING_PERSISTED_FIELDS');
        const mappedFields = await getPersistedFieldsFromDocument();

        setFieldsConfigStatus('CONFIG_MERGING_FIELDS');
        const initialFields = await getInitialFieldsFromDocument(
          campos_agrupados_por_tipo?.items
        );

        await updateFieldsStatus();

        const allDocFields = [...initialFields, ...mappedFields];

        setFieldsConfigStatus();
        persistNewMultiValuedFields([...new Map(allDocFields).values()]);
        setFields(new Map(allDocFields));
      },
    }
  );

  const fieldArray = useMemo(() => [...fields.values()], [fields]);

  const getPersistedFieldsFromDocument = async () => {
    const docFields = await getDocumentTags();
    const persistedFields = getPersistedFields();

    const valuedFields = await Promise.all(
      docFields.map(async (field) => {
        const fieldId = +getJSONField(field.tag, 'campo_id');
        const fieldName = getJSONField(field.tag, 'nome');
        const fieldDesc = getJSONField(field.tag, 'descricao');

        const persistedField = persistedFields.find(
          (item) => item.tagId === field.id
        );

        const fetchedField = await getFieldOptions(fieldId);

        return {
          fieldId,
          id: field.id,
          nome: fieldName,
          descricao: fieldDesc,
          valores: transformFieldValues(fetchedField?.valores),
          value: extractFieldValue({
            field,
            values: fetchedField?.valores,
            persistedField,
          }),
        };
      })
    );

    return extractFulfilledFields(valuedFields);
  };

  const getInitialFieldsFromDocument = async (groups) => {
    const mappedFields = transformFieldsToMap(groups);
    const newFields = await getUnmergeDocumentFields(
      mappedFields,
      getFieldOptions
    );

    const valuedFields = newFields.map((field) => {
      return {
        id: field.id,
        nome: field.nome,
        fieldId: field.fieldId,
        descricao: field.descricao,
        valores: transformFieldValues(field?.values),
        value: parseFieldValue(
          field.values?.length === 1 ? field.values[0] : null
        ),
      };
    });

    return extractFulfilledFields(valuedFields);
  };

  const clearFilter = () => {
    setSearch('');
  };

  const saveField = ({ values, ...restField }) => {
    setFields((mappedFields) => {
      const updatedMap = new Map(mappedFields);

      updatedMap.set(restField.id, {
        ...restField,
        valores: transformFieldValues(values),
        value: values.length > 1 ? null : parseFieldValue(values[0]),
      });

      return updatedMap;
    });
  };

  const addField = async ({ id, type, nome, descricao }) => {
    const fetchedField = await getFieldOptions(id);

    if (fetchedField && !fetchedField.sucesso) {
      enqueueToast(fetchedField?.motivo, {
        type: 'error',
        className: toastClasses.fullWidth,
      });
      return;
    }

    if (!fetchedField?.valores?.length) {
      enqueueToast(t('NO_FIELD_VALUES'), {
        type: 'error',
        className: toastClasses.fullWidth,
      });
      return;
    }

    const insertedField = await addTag({
      type,
      nome,
      fieldId: id,
      values: fetchedField?.valores,
      descricao,
    });

    if (!insertedField) {
      enqueueToast(t('NO_SELECTION_ON_DOCUMENT'), {
        type: 'error',
        className: toastClasses.fullWidth,
      });
      return;
    }

    saveField({ ...insertedField, descricao });
    persistField({ fieldId: id, tagId: insertedField.id });
  };

  const deleteField = async ({ id }) => {
    await removeTag(id);
    removePersistedField(id);

    setFields((mappedFields) => {
      const updatedMap = new Map(mappedFields);

      updatedMap.delete(id);

      return updatedMap;
    });
  };

  const updateFieldValue = async (id, newValue) => {
    setFields((mappedFields) => {
      const updatedMap = new Map(mappedFields);

      updatedMap.set(id, { ...updatedMap.get(id), value: newValue });

      return updatedMap;
    });

    await updateTag(id, newValue?.value || fields.get(id)?.nome);
    updatePersistedFieldValue({ tagId: id, value: newValue });
  };

  return (
    <FieldsContext.Provider
      value={{
        fieldGroups: fieldGroups?.campos_agrupados_por_tipo?.items || [],
        fieldArray,
        updateFieldValue,
        hasPendings,
        fieldsConfigStatus,
        loadingFieldGroups,
        search,
        searchDebounced,
        setSearch,
        clearFilter,
        addField,
        deleteField,
      }}
    >
      {children}
    </FieldsContext.Provider>
  );
};

FieldsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
