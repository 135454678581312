export const persistField = ({ fieldId, tagId, value = null }) => {
  const fields = JSON.parse(sessionStorage.getItem('localFields'));
  const mappedFields = new Map(fields);

  mappedFields.set(tagId, { fieldId, tagId, value });

  sessionStorage.setItem(
    'localFields',
    JSON.stringify([...mappedFields.entries()])
  );
};

export const persistNewMultiValuedFields = (fields = []) => {
  fields
    .filter((field) => field?.valores?.length > 1)
    .forEach((field) => {
      if (!getPersistedField(field.id)) {
        persistField({ fieldId: field.fieldId, tagId: field.id });
      }
    });
};

export const updatePersistedFieldValue = ({ tagId, value }) => {
  const fields = JSON.parse(sessionStorage.getItem('localFields'));
  const mappedFields = new Map(fields);

  mappedFields.set(tagId, { ...mappedFields.get(tagId), value });

  sessionStorage.setItem(
    'localFields',
    JSON.stringify([...mappedFields.entries()])
  );
};

export const getPersistedFields = () => {
  const fields = JSON.parse(sessionStorage.getItem('localFields'));
  const mappedFields = new Map(fields);

  return [...mappedFields.values()];
};

export const getPersistedField = (tagId) => {
  const fields = JSON.parse(sessionStorage.getItem('localFields'));
  const mappedFields = new Map(fields);

  return mappedFields.get(tagId);
};

export const removePersistedField = (tagId) => {
  const fields = JSON.parse(sessionStorage.getItem('localFields'));
  const mappedFields = new Map(fields);

  if (mappedFields.has(tagId)) {
    mappedFields.delete(tagId);
  }

  sessionStorage.setItem(
    'localFields',
    JSON.stringify([...mappedFields.entries()])
  );
};

export const cleanLocalFields = () => sessionStorage.removeItem('localFields');
