import { useState } from 'react';

const TABKEYS = {
  FILL_FIELDS: 'FILL_FIELDS',
  ADD_FIELDS: 'ADD_FIELDS',
};

export function useMerge() {
  const [selectedTab, setSelectedTab] = useState(TABKEYS.FILL_FIELDS);

  const onChangeTab = (_, value) => {
    setSelectedTab(value);
  };

  return { TABKEYS, selectedTab, onChangeTab };
}
