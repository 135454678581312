import { makeStyles } from '@bayon/commons';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > p': { margin: '2px 0px' },
  },

  icon: {
    width: 128,
    height: 'auto',
    marginBottom: 20,
    fill: theme.colors.neutralSecondaryAlt,
  },
}));
