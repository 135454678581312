/* global Office */

import React from 'react';
import ReactDOM from 'react-dom';

import App from './core/App';

let isOfficeInitialized = false;

const render = (Component) => {
  ReactDOM.render(
    <Component isOfficeInitialized={isOfficeInitialized} />,
    document.getElementById('container')
  );
};

Office.initialize = () => {
  isOfficeInitialized = true;
  render(App);
};
