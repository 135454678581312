/* eslint-disable no-useless-escape */
export const parseFieldValue = (value) => {
  return !value ? null : { value, label: value };
};

export const transformFieldValues = (values = []) =>
  values.map(parseFieldValue);

export const extractFulfilledFields = (fields = []) => {
  return fields.filter((field) => field).map((field) => [field.id, field]);
};

export const extractFieldValue = ({ field, values, persisted = {} }) => {
  if (persisted?.value) {
    return persisted?.value;
  }

  if (values.includes(field.text)) {
    return parseFieldValue(field.text);
  }

  return parseFieldValue(values?.length === 1 ? values[0] : null);
};

export const transformFieldsToMap = (groups) => {
  return groups.reduce((acc, group) => {
    group.campos.forEach((field) => {
      acc.set(field.nome, { ...field, type: group.id_tipo });
    });
    return acc;
  }, new Map());
};
