import { makeStyles, colors } from '@bayon/commons';

const baseStyles = {
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
};

export const useStyles = makeStyles({
  tabs: {
    padding: '10 0',
  },
  root: {
    ...baseStyles,
    overflow: 'hidden',
  },
  content: {
    ...baseStyles,
    padding: '10px',
    overflow: 'auto',
  },
  alert: {
    position: 'absolute',
    right: 2,
    width: 6,
    height: 6,
    borderRadius: '50%',
    border: '1px solid ' + colors.white,
    zIndex: 2,
    backgroundColor: colors.danger,
  },
  footer: {
    display: 'flex',
    margin: 5,
    justifyContent: 'end',
  },
});
