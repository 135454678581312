import { makeStyles } from '@bayon/commons';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
