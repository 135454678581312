import React, { useState } from 'react';

import { Button, Checkbox, Input, Typography } from '@bayon/commons';
import { useTranslation } from '@bayon/i18n';

import SAJLogo from '../../../../assets/SAJ_logo.svg';
import { useLogin } from '../../hooks';

import { useStyles } from './Login.styles';

export const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation('editorWordAddin');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const { errorMessage, logIn } = useLogin();

  const handleLoginChange = (e) => setUsername(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleRememberChange = (e) => setRemember(e.target.checked);

  const onSubmit = (event) => {
    event.preventDefault();
    logIn({ username, password, remember });
  };

  return (
    <main className={classes.root}>
      <form onSubmit={onSubmit} className={classes.form}>
        <div className={classes.logo}>
          <img src={SAJLogo} alt="SAJ Logo" />
        </div>
        {!!errorMessage?.length && (
          <Typography color="danger" size={14}>
            {errorMessage}
          </Typography>
        )}
        <Input
          required
          type="text"
          label={t('LOGIN')}
          name="Login"
          size="medium"
          value={username}
          placeholder=""
          errorText={t('REQUIRED_FIELD')}
          onChange={handleLoginChange}
        />
        <Input
          required
          type="password"
          label={t('PASSWORD')}
          name="Senha"
          size="medium"
          value={password}
          placeholder=""
          errorText={t('REQUIRED_FIELD')}
          onChange={handlePasswordChange}
        />
        <div>
          <Checkbox
            label={t('KEEP_LOGGIN')}
            checked={remember}
            onChange={handleRememberChange}
          />
        </div>
        <div>
          <Button variant="cta" type="submit">
            {t('ENTER')}
          </Button>
        </div>
      </form>
    </main>
  );
};
