import React, { useState } from 'react';

import { Button, Divider, Tabs, TabTitle, useToast } from '@bayon/commons';
import { useTranslation } from '@bayon/i18n';

import { useFields, useMerge, useWordDocument } from '../../hooks';
import { useStyles as useToastStyles } from '../../components/StyledToast';
import { ContentMessage } from '../../components';

import { FillFields, AddFields } from './fragments';

import { useStyles } from './Merge.styles';

export function Merge() {
  const classes = useStyles();
  const toastClasses = useToastStyles();
  const { t } = useTranslation('editorWordAddin');
  const { hasPendings, fieldsConfigStatus } = useFields();
  const { enqueueToast } = useToast();
  const { saveDocument, validateSave, errors } = useWordDocument();
  const { TABKEYS, onChangeTab, selectedTab } = useMerge();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { valid, save, DocumentoFinalizado } = await validateSave();

    if (!valid) {
      enqueueToast(t('DOCUMENT_WITH_REVIEW_STATUS'), {
        type: 'error',
        className: toastClasses.fullWidth,
      });

      setIsLoading(false);
      return;
    }

    if (!save) {
      enqueueToast(t('COULD_NOT_SAVE_DOCUMENT'), {
        type: 'error',
        className: toastClasses.fullWidth,
      });

      setIsLoading(false);
      return;
    }

    if (DocumentoFinalizado) {
      enqueueToast(t('DOCUMENT_IS_ALREADY_FINISHED'), {
        type: 'error',
        className: toastClasses.fullWidth,
      });

      setIsLoading(false);
      return;
    }

    const response = await saveDocument();

    if (!response?.ok) {
      enqueueToast(t('COULD_NOT_SAVE_DOCUMENT'), {
        type: 'error',
        className: toastClasses.fullWidth,
      });

      setIsLoading(false);
      return;
    }

    enqueueToast(t('DOCUMENT_SUCCESSFULLY_SAVED'), {
      type: 'success',
      className: toastClasses.fullWidth,
    });
    setIsLoading(false);
  };

  return (
    <>
      {!!errors?.length && <ContentMessage errors={errors} />}
      <form onSubmit={handleSubmit} className={classes.root}>
        <Tabs
          value={selectedTab}
          onChange={onChangeTab}
          className={classes.tabs}
        >
          <TabTitle
            value={TABKEYS.FILL_FIELDS}
            icon={
              <>
                {t('FILL_FIELDS')}
                {hasPendings && (
                  <div data-testid="fields-pending" className={classes.alert} />
                )}
              </>
            }
          />
          <TabTitle labelTitle={t('ADD_FIELDS')} value={TABKEYS.ADD_FIELDS} />
        </Tabs>
        <div className={classes.content}>
          {selectedTab === TABKEYS.FILL_FIELDS && <FillFields />}
          {selectedTab === TABKEYS.ADD_FIELDS && <AddFields />}
        </div>
        <Divider />
        <footer className={classes.footer}>
          <Button
            variant="cta"
            type="submit"
            disabled={
              hasPendings || !!errors?.length || isLoading || fieldsConfigStatus
            }
          >
            {t('SAVE')}
          </Button>
        </footer>
      </form>
    </>
  );
}
