import React from 'react';

import PropTypes from 'prop-types';

import Trash from '@bayon/svg-icons/icons/Trash';
import { useTranslation } from '@bayon/i18n';
import { IconButton, Tooltip, Select } from '@bayon/commons';

import { Inline, SelectContainer } from './FieldSelect.styles';

export const FieldSelect = ({
  field,
  onFocus,
  onBlur,
  onChange,
  onDelete,
  required,
}) => {
  const { t } = useTranslation(['common', 'editorWordAddin']);

  const handleFocus = (e) => {
    e.preventDefault();
    onFocus(field);
  };

  const handleBlur = (e) => {
    e.preventDefault();
    onBlur(field);
  };

  const handleChange = (value) => {
    onChange(field.id, value);
  };

  const handleDelete = () => onDelete(field);

  return (
    <div>
      <Inline
        xs={12}
        fullWidth
        wrap="nowrap"
        alignItems="flex-end"
        variant="flex"
      >
        <SelectContainer onFocus={handleFocus} onBlur={handleBlur}>
          <Select
            fullWidth
            required={required}
            id={field.id}
            data-testid="select"
            size="small"
            disabled={field?.valores?.length < 2}
            options={field?.valores}
            name={field.nome}
            label={field.nome}
            value={field.value}
            onChange={handleChange}
            isClearable={field?.valores?.length < 2}
          />
        </SelectContainer>
        <Tooltip label={t('DELETE_FIELD')} aria-label={t('DELETE_FIELD')}>
          <IconButton
            aria-label={t('DELETE')}
            variant="ghost"
            size="small"
            onClick={handleDelete}
          >
            <Trash />
          </IconButton>
        </Tooltip>
      </Inline>
    </div>
  );
};

FieldSelect.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    nome: PropTypes.string,
    valores: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};
