import React from 'react';

import PropTypes from 'prop-types';

import { Tooltip, Typography } from '@bayon/commons';

import { useFields } from '../../../../../../hooks';

import { useStyles } from './TreeSubItem.styles';

export const TreeSubItem = ({ id, nome, descricao, type }) => {
  const classes = useStyles();
  const { addField } = useFields();

  const handleAddField = () => addField({ id, nome, descricao, type });

  return (
    <Tooltip
      aria-label={descricao}
      label={descricao}
      placement="top"
      textAlign="left"
    >
      <Typography
        size={12}
        noWrap
        weight="regular"
        display="block"
        data-testid={`tree-sub-item-${id}`}
        className={classes.root}
        onClick={handleAddField}
      >
        {nome}
      </Typography>
    </Tooltip>
  );
};

TreeSubItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  nome: PropTypes.string.isRequired,
  descricao: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
