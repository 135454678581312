import React from 'react';

import bayonConfig from '@core/bayon.config';

import EmptyServer from '@bayon/svg-icons/icons/EmptyServer';
import { Typography } from '@bayon/commons';
import { useTranslation } from '@bayon/i18n';

import { useStyles } from './ErrorScreen.styles';

export const ErrorScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation('editorWordAddin');

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <EmptyServer className={classes.icon} aria-hidden="true" />
        <div className={classes.content} role="alert" aria-live="polite">
          <Typography size={22} weight="light" color="neutralSecondaryAlt">
            {bayonConfig.header.appName}
          </Typography>
          <Typography color="neutralSecondaryAlt">
            {t('AN_INTERNAL_ERROR_HAS_OCCURRED')}
          </Typography>
          <Typography
            size={12}
            weight="regular"
            data-testid="error-number"
            color="neutralTertiary"
          >
            {t('ERROR_500')}
          </Typography>
        </div>
      </div>
    </div>
  );
};
