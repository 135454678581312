import bayonConfig from '@core/bayon.config';

import { useQuery, useApolloClient } from '@bayon/fetch';

import { useWordDocument } from './useWordDocument';

export const useFieldsFetcher = (config) => {
  const client = useApolloClient();
  const { customProperties } = useWordDocument();

  const { data: fieldGroups, loading: loadingFieldGroups } = useQuery(
    bayonConfig.queries.field_groups,
    config
  );

  const getFieldOptions = async (fieldId) => {
    try {
      const { data } = await client.query({
        query: bayonConfig.queries.field_options,
        variables: {
          filter: {
            campo_id: fieldId,
            processo_id: customProperties?.cdprocessoni,
          },
        },
      });
      return data?.campo_particulas;
    } catch {
      return null;
    }
  };

  return {
    fieldGroups,
    loadingFieldGroups,
    getFieldOptions,
  };
};
