import { makeStyles } from '@bayon/commons';

export const useStyles = makeStyles((theme) => ({
  container: {
    border: `1px solid ${theme.colors.neutralTertiaryAlt}`,
  },
  input: {
    border: 'none',
    display: 'block',
    width: '80%',
    padding: 12,
    outline: 'none',
  },
  containerInputWithIcon: {
    display: 'flex',
  },
  icon: {
    height: 16,
    width: 16,
    margin: 'auto',
    cursor: 'pointer',
  },
}));
