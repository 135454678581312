import { makeStyles } from '@bayon/commons';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  logo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '40px 0px 40px 0px',
  },
  form: {
    display: 'grid',
    gridRowGap: '8px',
    maxWidth: 268,
    width: 268,
  },
});
