/* eslint-disable no-unsafe-optional-chaining */
/* global Word Office*/

export function useWordProperties() {
  const getFileName = () => {
    return new Promise((resolve) => {
      Office.context.document.getFilePropertiesAsync(null, (res) => {
        if (res?.value?.url) {
          const rawName = res.value.url
            .substr(res?.value?.url.lastIndexOf('/') + 1)
            .split('.')[0];
          const withoutVersion = rawName.split(' ')[0];
          resolve(withoutVersion);
        }
        resolve('');
      });
    });
  };

  const get = (targetKey) => {
    return Word.run(async (context) => {
      const { customProperties } = context?.document?.properties;

      customProperties.load('key,type,value');
      await context.sync();

      if (targetKey) {
        const property = customProperties.items.find(
          (item) => item.key === targetKey
        );

        return property?.value;
      }

      return customProperties.items.reduce(
        (acc, { key, value }) => ({
          ...acc,
          [key]: value,
        }),
        {}
      );
    });
  };

  const set = (key, value) => {
    return Word.run(async (context) => {
      const { customProperties } = context?.document?.properties;

      await context.sync();

      customProperties.add(key, value);

      return context.sync();
    });
  };

  return { wordProperties: { get, set, getFileName } };
}
