const getJSONFieldIndex = (json, field) => json.lastIndexOf(`"${field}":`);
const getJSONTuple = (json, field) => {
  const matches = json.match(/"(.+?)":\s?(".+?"|\d+|null|true|false)/g);

  return matches.find((match) => getJSONFieldIndex(match, field) > -1);
};

const reverseString = (str) => {
  let splitString = str.split('');
  let reverseArray = splitString.reverse();
  let joinArray = reverseArray.join('');

  return joinArray;
};

export const getJSONField = (json, field) => {
  const match = getJSONTuple(json, field)
    ?.replace(`"${field}":`, '')
    .replace(/"/g, '')
    .trim();

  if (match) {
    switch (match) {
      case 'null':
        return null;
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return match;
    }
  }

  return null;
};

export const setJSONField = (json, field, value) => {
  let match = reverseString(json);
  const tuple = reverseString(getJSONTuple(json, field));
  match = match.replace(
    tuple,
    `"${reverseString(value)}" :"${reverseString(field)}"`
  );

  return reverseString(match);
};

export const findJSONString = (json) => {
  return json.match(/\{(?:[^{}]|())*\}/gm)?.[0];
};
