import React from 'react';

import { useFields } from '../../../../hooks';
import {
  InputSearch,
  ResultFeedback,
  Loader,
  TreeView,
} from '../../../../components';

import { useStyles } from './AddFields.styles';

export function AddFields() {
  const classes = useStyles();
  const { loadingFieldGroups } = useFields();

  return (
    <>
      <InputSearch />
      {!loadingFieldGroups && <ResultFeedback />}
      <div className={classes.container}>
        {loadingFieldGroups ? <Loader /> : <TreeView />}
      </div>
    </>
  );
}
