import { makeStyles } from '@bayon/commons';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    paddingLeft: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.neutralQuaternary,
    },
  },
}));
