import { hot } from 'react-hot-loader/root';
import React, { useMemo } from 'react';
import { HashRouter as Router } from 'react-router-dom';

import PropTypes from 'prop-types';
import bayonConfig from '@core/bayon.config';

import { ToastProvider } from '@bayon/commons';
import { BayonI18NextProvider } from '@bayon/i18n';
import { BayonFetchProvider } from '@bayon/fetch';

import { KeycloakProvider, WordProvider, FieldsProvider } from '../contexts';
import { Loader, ErrorBoundary } from '../components';
import { getTenant } from '../utils';

import { Routes } from './routes';

import { useStyles } from './App.styles';

function Wrapper({ isOfficeInitialized }) {
  const classes = useStyles();

  const tenantLabel = useMemo(() => {
    const { isMultitenancy, tenantName } = getTenant(bayonConfig);
    // eslint-disable-next-line no-unused-expressions
    isMultitenancy
      ? localStorage.setItem('tenant', tenantName)
      : localStorage.removeItem('tenant');
    return isMultitenancy ? { ['Tenant']: tenantName } : null;
  }, []);

  if (!isOfficeInitialized) {
    return <Loader />;
  }

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <BayonI18NextProvider
          language={bayonConfig.i18n.language}
          url={bayonConfig.i18n.url}
          dictionarys={bayonConfig.i18n.dictionarys}
        >
          <ToastProvider>
            <KeycloakProvider>
              <BayonFetchProvider
                uri={bayonConfig.apollo.url}
                apm={{ ...bayonConfig.apollo.apm, labels: { ...tenantLabel } }}
              >
                <WordProvider>
                  <FieldsProvider>
                    <Router className={classes.router}>
                      <Routes />
                    </Router>
                  </FieldsProvider>
                </WordProvider>
              </BayonFetchProvider>
            </KeycloakProvider>
          </ToastProvider>
        </BayonI18NextProvider>
      </div>
    </ErrorBoundary>
  );
}

Wrapper.propTypes = {
  isOfficeInitialized: PropTypes.bool,
};

function App({ isOfficeInitialized }) {
  return (
    <React.StrictMode>
      <Wrapper isOfficeInitialized={isOfficeInitialized} />
    </React.StrictMode>
  );
}

App.propTypes = {
  isOfficeInitialized: PropTypes.bool,
};

export default hot(App);
